import Error404Illustration from '@/public/images/error/404.svg';
import { ErrorPage } from '@/src/components/Ui/Error';

const title = `Sorry, the page you're looking for could not be found.`;
const description =
  'Please check if the address is typed correctly or contact your Remote representative responsible for your employment directly.';

const NotFoundPage = () => {
  return (
    <ErrorPage
      siteTitle="Page not found"
      image={<Error404Illustration />}
      title={title}
      description={description}
      trackNavigation={['dashboard']}
    />
  );
};

export default NotFoundPage;
